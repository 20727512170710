export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,8,9,12,13,11,18];

export const dictionary = {
		"/(auth)/callbacks/facebook": [~19,[2]],
		"/(auth)/callbacks/google": [~20,[2]],
		"/(auth)/create-profile": [~21,[3]],
		"/(protected)/dashboard": [~26,[7,8]],
		"/(protected)/dashboard/account": [27,[7,8]],
		"/(protected)/dashboard/account/billing": [28,[7,8]],
		"/(protected)/dashboard/account/profile": [29,[7,8]],
		"/(protected)/dashboard/account/profile/notifications": [30,[7,8]],
		"/(protected)/dashboard/account/profile/security": [31,[7,8]],
		"/(protected)/dashboard/account/subscriptions": [~32,[7,8]],
		"/(protected)/dashboard/account/team": [~33,[7,8]],
		"/(protected)/dashboard/analytics": [~34,[7,8]],
		"/(protected)/dashboard/leads": [~35,[7,8]],
		"/(protected)/dashboard/marketing": [36,[7,8,9]],
		"/(protected)/dashboard/marketing/[generateCampaignStep]": [~37,[7,8,9]],
		"/(protected)/dashboard/websites": [38,[7,8]],
		"/(protected)/dashboard/websites/domains": [~39,[7,8]],
		"/(protected)/dashboard/websites/seo": [40,[7,8]],
		"/(auth)/email-confirmation": [~22,[4]],
		"/flow": [~57],
		"/flow/01/1": [~58,[12]],
		"/flow/01/2": [~59,[12]],
		"/flow/02/1": [~60,[13]],
		"/flow/02/2": [~61,[13]],
		"/flow/02/3": [62,[13]],
		"/(auth)/login": [~23],
		"/(protected)/lp/edit": [~41,[7,10]],
		"/(protected)/lp/edit/preview": [42,[7,10]],
		"/maintenance": [~63],
		"/(protected)/marketing/create": [43,[7,11]],
		"/(protected)/marketing/create/budget": [~44,[7,11]],
		"/(protected)/marketing/create/checkout": [45,[7,11]],
		"/(protected)/marketing/create/discount": [46,[7,11]],
		"/(protected)/marketing/create/email": [47,[7,11]],
		"/(protected)/marketing/create/location-2": [49,[7,11]],
		"/(protected)/marketing/create/location-2/cities": [~50,[7,11]],
		"/(protected)/marketing/create/location-2/states": [~51,[7,11]],
		"/(protected)/marketing/create/location": [48,[7,11]],
		"/(protected)/marketing/create/phone": [52,[7,11]],
		"/(protected)/marketing/create/preview": [53,[7,11]],
		"/(protected)/marketing/create/service": [54,[7,11]],
		"/(protected)/marketing/waitlist": [55,[7]],
		"/onboard": [~64],
		"/onboard/preview": [~65,[14]],
		"/onboard/preview/[lpKey]": [~66,[14]],
		"/(protected)/payment/inprogress": [56,[7]],
		"/(auth)/recover-password": [~24,[5]],
		"/(auth)/reset-password": [~25,[6]],
		"/website-builder/(inprogress)/inprogress": [67,[16]],
		"/website-builder/(register)/register": [~68,[17]],
		"/website-source": [69,[18]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';